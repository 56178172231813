@import "react-slideshow-image/dist/styles.css";

@font-face {
    font-family: 'cpmono';
    font-weight: normal;
    src: url('../fonts/cpmono-plain-webfont.woff') format('woff'),
         url('../fonts/cpmono-plain-webfont.woff2') format('woff2');
}

@font-face {
    font-family: 'cpmono';
    font-weight: bold;
    src: url('../fonts/cpmono-bold-webfont.woff') format('woff'),
         url('../fonts/cpmono-bold-webfont.woff2') format('woff2');
}

@font-face {
    font-family: 'spacemono';
    font-weight: normal;
    src: url('../fonts/spacemono-regular-webfont.woff') format('woff'),
         url('../fonts/spacemono-regular-webfont.woff2') format('woff2');
}

@font-face {
    font-family: 'spacemono';
    font-weight: bold;;
    src: url('../fonts/spacemono-bold-webfont.woff') format('woff'),
         url('../fonts/spacemono-bold-webfont.woff2') format('woff2');
}

body{
    background-color: #000;
    height: 100%;
    overflow: hidden;
    color: #FFFFFF;
    font-family: spacemono, monospace;
    font-size: 0.9em;
}

p{
    text-align: center;
}

.desktop-only{
    display: block;
}

.mobile-only{
    display: none;
}

.notched{
    --notchSize: 28px;
    clip-path: 
    polygon(
      0% var(--notchSize), 
      var(--notchSize) 0%, 
      calc(100% - var(--notchSize)) 0%, 
      100% var(--notchSize), 
      100% calc(100% - var(--notchSize)), 
      calc(100% - var(--notchSize)) 100%, 
      var(--notchSize) 100%, 
      0% calc(100% - var(--notchSize))
    );
    transform: translateY(20px);
}

.text-orange{
    color: #EF5A29;
}

h2{
    font-family: cpmono;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 3em;
}

@media only screen and (max-width: 760px){
    h2{
        font-size: 2em;
        margin-bottom: 0px;
    }
}

h3{
    font-family: cpmono;
    text-transform: uppercase;
    font-size: 1em;
    text-align: center;
    margin-bottom: 5px;
    margin-top: 5px;
    color: #fff;
}

h4{
    text-align: center;
}

.mint-top{
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 140px;
    transform: translateX(-50%);
    z-index: 2;
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
    font-size: 2em;
    font-family: cpmono;
    letter-spacing: 5px;
}

.mint-left{
    position: absolute;
    z-index: 2;
    left: -30px;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    z-index: 2;
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
    font-size: 2em;
    font-family: cpmono;
    letter-spacing: 5px;
}

.mint-right{
    position: absolute;
    z-index: 2;
    right: -30px;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    z-index: 2;
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
    font-size: 2em;
    font-family: cpmono;
    letter-spacing: 5px;
}

.slider-container{
    position: absolute;
    left: -10%;
    right: -10%;
    top: 0%;
    bottom: -10%;
    z-index: 1;
}

.slider-image{
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translateX(-50%);
    background-position: center center;
    background-size: cover;
    display: none;
}

.slider-image.active{
    display: block;
}

.slide-container img{
    width: 100%;
    height: 100%;
}

.mask{
    position: fixed;
    z-index: 99999;
    left: 0;
    bottom: 0;
    right: 0;
    height: 3px;
    background-color: #000;
}


.panel{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 20;
    pointer-events: none;
}


.logo{
    z-index: 3;
    position: absolute;
    top: -3px;
    z-index: 9999;
    left: 50%;
    background-color: #000;
    transform: translateX(-50%);    
    margin-left: 4px;
    padding-top: 0.7%;
    cursor: pointer;
    pointer-events: all;
}

.logo img{
    width: 14vh;
}

.panel-left{
    position: absolute;
    /*
        300x429
        544x700
    */
    left: 0;
    top: 0;
    bottom: 0;
    width: calc(30vh);  
    z-index: 3;
    pointer-events: all;
}

.panel-right{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: calc(30vh);
    z-index: 3;
    pointer-events: all;
}

.panel-bottom{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2vh;
    z-index: 3;
    pointer-events: all;
    text-align: center;

    
}

.panel-bottom a{
    margin: 0 10px;
    display: inline-block;
    vertical-align: middle;
    transition: opacity 0.1s ease-out;
    opacity: 1;
}

.panel-bottom a:hover{
    opacity: 0.7;
}

.panel-bottom a img{
    width: auto;
    height: 2.4vh;
}

.wallet-button{
    border: 2px solid #ffe6c2;
    padding: 0.5vh 1.5vh;
    width: 160px;
    text-transform: uppercase;
    margin-left: 8px;
    font-size: 0.9em;
    transition: opacity 0.1s ease-out;
}

.wallet-button:hover{
    opacity: 0.7;
}

.mobile-wallet-button{
    border: 2px solid #ffe6c2;
    padding: 0.4vh 1.3vh;
    margin-left: 5px;
}

.mobile-address{
    border: 2px solid #ffe6c2;
    padding: 0.5vh 1.5vh;
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
}

@media only screen and (max-height: 670px){
    .wallet-button{
        border: 0;
    }    
}


.panel-border{
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-image: url(../images/panel-border-hover.svg);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: top center;
    z-index: 2;
}


.panel-border:hover{
    background-image: url(../images/panel-border-hover.svg);
}

.panel-mobile-only{
    bottom: 0;
    position: fixed;
}

.panel-mobile-only .mint-title{
    margin-top: 1vh;
}



.panel-border-mobile{
    position: fixed;
    pointer-events: none;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-image: url(../images/panel-mobile.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 2;
}



.content{

   
    width: calc(100vh * 0.89);
    margin: 0 auto;
    z-index: 1;
    position: relative;
}

@media only screen and (max-width: 760px){
    .content{
        width: 100%;
    }
}


.content > div:last-child{
    padding-bottom: 0px;
}

.panel-container{
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 3;
    pointer-events: none;
}

.panel-center{
    width: 54vw;
}

.panel-button{
    height: 20%;
    width: 100%;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
}



.panel-button:before,
.mobile-button:before{
    width: 0;
    height: 0;
    visibility: hidden;
}

.BrainhubCarousel__container{
    padding: 10px;
}


.panel-button.button-1{
    left: 0;
    top: 8%;
}

.button-1{
    background-image: url(../images/buttons/1-mob.png);
}

.button-1:before {
    content: url(../images/buttons/1-hover-e.gif);
}

.button-1:not(.mobile-button):hover, .button-1.active{
    background-image: url(../images/buttons/1-hover-e.gif);
}



.mobile-button.button-1{
    background-image: url(../images/buttons/1-hover-e.gif);
}
.mobile-button.button-1:before {
    content: url(../images/buttons/1-hover-e.gif);
}
.mobile-button.button-1.active{
    background-image: url(../images/buttons/1-hover-e.gif);
}


.panel-button.button-2{
    left: 0;
    top: 34.5%;
}

.button-2{
    background-image: url(../images/buttons/2-mob.png);
}

.button-2:before {
    content: url(../images/buttons/2-hover-e.gif);
}

.button-2:not(.mobile-button):hover, .button-2.active{
    background-image: url(../images/buttons/2-hover-e.gif);
}

.mobile-button.button-2{
    background-image: url(../images/buttons/2-hover-e.gif);
}
.mobile-button.button-2:before {
    content: url(../images/buttons/2-hover-e.gif);
}
.mobile-button.button-2.active{
    background-image: url(../images/buttons/2-hover-e.gif);
}


.panel-button.button-3{
    left: 0;
    top: 60%;
    height: 20%;
}

.button-3{
    background-image: url(../images/buttons/3-mob.png);
}

.button-3:not(.mobile-button):hover, .button-3.active{
    background-image: url(../images/buttons/3-hover-mob-e.gif);
}

.button-3:before {
    content: url(../images/buttons/3-hover-mob-e.gif);
}

.mobile-button.button-3{
    background-image: url(../images/buttons/3-hover-mob-e.gif);
}
.mobile-button.button-3:before {
    content: url(../images/buttons/3-hover-mob-e.gif);
}
.mobile-button.button-3.active{
    background-image: url(../images/buttons/3-hover-mob-e.gif);
}


.panel-button.button-4{
    right: 0;
    top: 8%;
}

.button-4{
    background-image: url(../images/buttons/4-mob.png);
}

.button-4:before {
    content: url(../images/buttons/4-hover-e.gif);
}

.button-4:not(.mobile-button):hover, .button-4.active{
    background-image: url(../images/buttons/4-hover-e.gif);
}

.mobile-button.button-4{
    background-image: url(../images/buttons/4-hover-e.gif);
}

.mobile-button.button-4:before {
    content: url(../images/buttons/4-hover-e.gif);
}

.mobile-button.button-4.active{
    background-image: url(../images/buttons/4-hover-e.gif);
}


.panel-button.button-5{
    right: 0;
    top: 34.5%;
}

.button-5{
    background-image: url(../images/buttons/5-mob.png);
}

.button-5:not(.mobile-button):hover, .button-5.active{
    background-image: url(../images/buttons/5-hover-e.gif);
}

.button-5:before {
    content: url(../images/buttons/5-hover-e.gif);
}

.mobile-button.button-5{
    background-image: url(../images/buttons/5-hover-e.gif);
}
.mobile-button.button-5:before {
    content: url(../images/buttons/5-hover-e.gif);
}
.mobile-button.button-5.active{
    background-image: url(../images/buttons/5-hover-e.gif);
}


.panel-button.button-6{
    left: 0;
    top: 60%;
    height: 20%;
}

.button-6{
    background-image: url(../images/buttons/6-mob.png);
}

.button-6:not(.mobile-button):hover,
.button-6.active{
    background-image: url(../images/buttons/6-hover-e.gif);
}

.button-6:before {
    content: url(../images/buttons/6-hover-e.gif);
}

.mobile-button.button-6{
    background-image: url(../images/buttons/6-hover-e.gif);
}
.mobile-button.button-6:before {
    content: url(../images/buttons/6-hover-e.gif);
}
.mobile-button.button-6.active{
    background-image: url(../images/buttons/6-hover-e.gif);
}


.panel-button.button-7{
    right: 0;
    top: 80.1%;
    cursor: pointer;
}

.button-7{
    height: 20%;
    background-image: url(../images/buttons/7.png);
}

.button-7:not(.mobile-button):hover,
.button-7.active{
    /* top: 84.1%;
    height: 12.06%; */
    background-image: url(../images/buttons/7-hover-e.gif);
}

.button-7:before {
    content: url(../images/buttons/7-hover-e.gif);
}

.panel-button.button-8{
    right: 0;
    top: 80.1%; 
}

.button-8{
    height: 20%;
    background-image: url(../images/buttons/8.png);
}

.button-8:hover,
.button-8.active{
    /* top: 84%;
    height: 12.0%; */
    background-image: url(../images/buttons/8-hover-e.gif);
}

.button-8:before {
    content: url(../images/buttons/8-hover-e.gif);
}

.mobile-bottom-panel{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 130px;
    z-index: 99;
    background-color: #000;
}


.mobile-bottom-panel::before{
    content: '';
    position: absolute;
    top: -20px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 20px 20px;
    border-color: transparent transparent transparent #000;
    z-index: 999999;
}

.mobile-bottom-panel::after{
    content: '';
    position: absolute;
    top: -20px;
    right: 0px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 20px 20px;
    border-color: transparent transparent #000 transparent;
    z-index: 999999;
}

.mobile-buttons-track{
    overflow: hidden;
    transition: transform 0.3s ease-out;
    height: 122px;
}


.mobile-buttons{
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    transition: left 0.3s cubic-bezier(0.770, 0.000, 0.175, 1.000);
}

.mobile-button{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    width: 128px;
    height: 122px;
}


.section{
    position: relative;
}

.section:not(.section-home){
    min-height: 90vh;
    align-items: center;
}

.section:not(.section-home):not(.section-vision):not(.section-roadmap){
    padding-top: 150px;
}

.section-roadmap{
    padding-top: 100px;
}

@media only screen and (max-width: 760px){
    .section-roadmap{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }   
}

.mobile-header{
    position: relative;
    display: flex;
    z-index: 9;
    padding-top: 5px;
    pointer-events: all;
}

.mobile-header img{
    width: 120px;
    margin: 3px 0 0 10px;
}



.mobile-social{
    flex-grow: 1;
    text-align: right;
    padding-right: 10px;
    padding-top: 6px;
}

.mobile-social a{
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
}

.mobile-social a img{
    height: 20px;
    width: auto;
}

@media only screen and (max-width: 760px){
    .section:not(.section-home){
        padding: 80px 30px;
    }
}

.section-content{
    align: center;
    display: inline-flex;
    margin-top:0%;
    width: 100%;
}

.section-home{
    z-index: 9;
    margin-top: -20px;
    margin-left: -20px;
    margin-right: -20px;
}

@media only screen and (max-width: 760px){
    .section-home{
        margin-left: 0px;
        margin-right: 0px;
        background-color: #000;
    }    
}

.section-welcome{
    z-index: 8;
    margin-top: -40px;
    background-color: #231F20;
}

.section-vision{
    z-index: 7;
    margin-top: -40px;
    background-color: #f05b29;
}

.section-universe{
    z-index: 6;
    margin-top: -40px;
    background-color: #231F20;
}

.universe-carousel-container{
    margin: 40px -10px;
    max-width: 500px;
    margin: 0 auto;
}

@media only screen and (max-width: 760px){
    .universe-carousel-container{
        margin: 20px -10px;
    }
}

.section-mint{
    z-index: 5;
    margin-top: 0px;
    background-color: #231f20;

}

.section-roadmap{
    z-index: 4;
    margin-top: -40px;
    background-color: #231f20;
}

.section-roadmap h3{
    margin-top: 20px;
}

.section-team{
    z-index: 3;
    margin-top: -40px;
    background-color: #383234;
}

.flags{
    text-align: center;
    margin-top: 40px;
}

.flags img{
    width: 30px;
    display: inline-block;
    margin: 0 8px;
}

.team-members{
    margin-top: 80px;
}

.team-member{
    text-align: center;
    font-size: 0.9em;
    margin-bottom: 20px;
}

@media only screen and (max-width: 760px){
    .team-member{
        margin-bottom: 40px;
    }
    .team-members{
        margin-top: 40px;
    }
}

.team-member img{
    border-radius: 50%;
    max-width: 140px;
    display: inline-block;
    margin-bottom: 20px;
}

.member-name{
    color: #EF5A29;
    font-size: 1.6em;
    letter-spacing: 2px;
    font-family: cpmono;
    text-transform: uppercase;
    margin-bottom: 0px;
}

@media only screen and (max-width: 760px){
    .member-name{
        font-size: 1.1em;
    }
}


.section-faq{
    z-index: 2;
    margin-top: -40px;
    background-color: #424242;
}

.faq-question{
    margin-bottom: 30px;
}

.faq-q{
    font-weight: bold;
    margin-bottom: 10px;
}

.section-join{
    z-index: 1;
    transform: translateY(-20px);
    background-color: #231F20;
}

.social-links{
    display: flex;
}

.social-links a{
    outline: none;
    width: 50%;
    display: inline-block;
    margin: 0 20px;
    opacity: 1;
    transition: all 0.2s ease-out;
}

.social-links a:hover{
    opacity: 0.7;
}

.social-links img{
    width: 100%;
}

video{
    
}

.video-play{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
}

.video-thumb{
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding-bottom: 100%;
    background-image: url(../images/video-mobile-gif.gif);
    background-size: cover;
    background-position: center center;
    z-index: 8;
}

.button{
    display: inline-block;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #ffe6c2;
    color: #000;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all .3s ease;
}

.button:hover{
    background-color: #6be4b7;
}

/* mint section */


.mint-illustration a{


    opacity: 100%;
    transform:scale(1.1);
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    z-index: -1;
    align: top;
    display: flex;
    object-fit: fill;
}

.mint-illustration a:hover{

    transform:scale(1.15);
    opacity: 100%;
    

}


.mint-title{
    position: absolute;
    margin-top: 3%;
    z-index: 99;
    width: 100%;
}



.mint-content{
    position: flex;
    width: 100%;
    
}

@media only screen and (max-width: 4000px){
    .mint-title{
        top: 10%;
    }
    .mint-illustration{

    }
    .mint-content{
        padding-top: 0px;
    }
}



.mint-qty{
    background: transparent;
    border: 1px solid #6be4b7;
    outline: none;
    padding: 6px 12px;
    border-radius: 10px;
    width: 60px;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-circular{
    background: rgb(129,140,248);
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    line-height: 50px;
    font-size: 1.2em;
    opacity: 0.7;
    outline: none;
    transition: all 0.2s ease-in-out;
}

.button-circular:hover{
    opacity: 1;
}

#intro-video{
    width: 100%;
}

.web3modal-modal-lightbox{
    z-index: 9999 !important;
}

#walletconnect-wrapper{
    color: #000;
}

@media only screen and (max-width: 760px){

    #intro-video{
        width: 100vw;
        height: auto;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(calc(-50% + 20px));
    }

    .video-thumb{
        transform: translateY(calc(-50% + 20px));
    }

    .video-play{
        margin-top: 22px;
    }

    .section.section-home{
        min-height: calc(100vh - 100px + 20px);
    }
    
    
    .section-mint{
        padding-top: 0 !important;
    }

    .mint-top{
        top: 50px;
    }

    .flags{
        margin-top: 30px;
        padding-bottom: 30px;
    }

    .flags img{
        width: 20px;
        margin-bottom: 15px;
        margin: 0 5px;
    }

    .slider-container{
        left: 0;
        right: 0;
    }

    .mint-left{
        left: -90px;
        top: 58%;
    }

    .mint-right{
        right: -90px;
        top: 58%;
    }

    

    .desktop-only{
        display: flex;
    }
    
    .mobile-only{
        display: block;
    }    
}



